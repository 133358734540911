<template>
	<ion-page id="MaDetail">
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button
						text="Zurück"
						default-href="/mitarbeiter"
					></ion-back-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true" class="ion-padding">
			<div class="header">
				<ion-avatar>
					<img :src="imgLink" class="avatar" />
				</ion-avatar>
				<h1 class="slabs">
					{{ user.first_name }} {{ user.last_name }}
				</h1>
			</div>

			<ion-item class="ion-margin-top" lines="none">
				<ion-icon :icon="mailOutline" class="ion-margin-end"></ion-icon>
				{{ user.email }}
			</ion-item>

			<!-- <div class="ion-padding">
				<p>Abgeschlossene Kurse: {{ doneKapitel }}</p>
				<ion-progress-bar
					:value="doneKapitel / kurseAmount"
					class="ion-margin-top"
				></ion-progress-bar>

				<p>Angefangene Kurse: {{ startedKapitel - doneKapitel }}</p>
				<ion-progress-bar
					:value="(startedKapitel - doneKapitel) / kurseAmount"
					class="ion-margin-top"
					color="secondary"
				></ion-progress-bar>
			</div> -->

			<ion-grid class="tabbar-b-margin">
				<h3 class="ion-padding slabs" v-if="user.badges">
					Abgeschlossene Kurse: {{ user.badges.length }}
				</h3>

				<ion-row class="">
					<ion-col
						class="badge"
						v-for="badge in user.badges"
						:key="badge"
						size="6"
						size-sm="6"
						size-xl="4"
					>
						<ion-avatar class="image">
							<img :src="badgeLink(badge.icon)" />
						</ion-avatar>
						<h3>{{ badge.name }}</h3>
					</ion-col>
				</ion-row>
			</ion-grid>

			<ion-grid class="tabbar-b-margin ion-margin-top">
				<h3 class="ion-padding slabs">
					Angefangene Kurse: {{ startedKurse.length }}
				</h3>
				<ion-row class="">
					<ion-col
						class="badge started"
						v-for="badge in startedKurse"
						:key="badge"
						size="6"
						size-sm="6"
						size-xl="4"
					>
						<ion-avatar class="image">
							<img :src="badgeLink(badge.icon)" />
						</ion-avatar>
						<h3>{{ badge.name }}</h3>
					</ion-col>
				</ion-row>
			</ion-grid>

			<ion-button
				color="danger"
				expand="block"
				class="button"
				fill="outline"
				@click="deleteUser()"
			>
				<ion-icon
					:icon="trashOutline"
					class="ion-margin-end"
				></ion-icon>
				Mitarbeitende löschen
			</ion-button>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonButtons,
	IonButton,
	IonBackButton,
	IonContent,
	IonHeader,
	IonPage,
	IonItem,
	IonAvatar,
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	IonToolbar,
	// IonProgressBar,
} from '@ionic/vue';
import { mailOutline, trashOutline } from 'ionicons/icons';

export default {
	name: 'MaDetail',
	components: {
		IonButtons,
		IonButton,
		IonBackButton,
		IonContent,
		IonHeader,
		IonPage,
		IonItem,
		IonIcon,
		IonAvatar,
		IonGrid,
		IonRow,
		IonCol,
		IonToolbar,
		// IonProgressBar,
	},
	setup() {
		return {
			mailOutline,
			trashOutline,
		};
	},
	data() {
		return {
			user: {},
			kurseAmount: 0,
			alleKurse: {},
		};
	},
	computed: {
		imgLink() {
			return `${this.$store.state.imageBase}${
				this.user.avatar || 'dcf0ce07-4687-4185-8589-b5f442200498'
			}?access_token=${localStorage.getItem('auth_token')}`;
		},
		startedKapitel() {
			if (!this.user.progress) return 0;
			return Object.keys(this.user.progress).length;
		},
		doneKapitel() {
			let done = 0;
			for (const key in this.user.progress) {
				if (this.user.progress[key].done) done++;
			}
			return done;
		},
		startedKurse() {
			// get started Kapitel from user
			const kurse = [];
			for (let kurs in this.user.progress) {
				if (!this.user.progress[kurs].done) {
					const addKurs = this.alleKurse.find(
						(elem) => elem.id.toString() === kurs
					);
					if (addKurs) kurse.push(addKurs);
				}
			}
			// return object for view
			return kurse;
		},
	},
	methods: {
		badgeLink(badge) {
			return `${
				this.$store.state.imageBase
			}${badge}?access_token=${localStorage.getItem('auth_token')}`;
		},
		async deleteUser() {
			this.$store.dispatch('updateLoading', true);
			try {
				await this.$http.delete(`/users/${this.user.id}`);
			} catch (e) {
				console.log(e);
			}
			this.$store.dispatch('updateLoading', false);
			this.$router.push('/mitarbeiter');
		},
		async ionViewWillEnter() {
			try {
				const { data } = await this.$http.get(
					`/users/${this.$route.params.id}`
				);
				const alleKurse = await this.$http.get(
					'/items/kurse?fields=id,name,icon'
				);
				this.kurseAmount = alleKurse.data.data.length;
				this.alleKurse = alleKurse.data.data;
				this.user = data.data;
			} catch (e) {
				console.log(e);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#MaDetail {
	ion-content {
		--background: white;
	}

	.header {
		text-align: center;

		ion-avatar {
			height: 7rem;
			width: 7rem;
			margin: 2rem auto;
			background: white;
			filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
			overflow: hidden;
			img {
				margin-top: 0.3rem;
			}
		}
	}
	.badge {
		display: flex;
		justify-content: center;
		flex-direction: column;
		ion-avatar {
			margin: 0 auto;
		}
		text-align: center;
		width: 200px;

		h3 {
			font-size: 14px;
			margin-bottom: 0px;
		}

		.label {
			font-size: 12px;
		}

		&.started {
			img {
				-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
				filter: grayscale(100%);
			}
		}

		@media screen and (min-width: 768px) {
			h3 {
				font-size: 22px;
			}

			.label {
				font-size: 14px;
			}
		}
	}
}
</style>
